.ImagePrompt {  
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
  margin: 2rem auto;
  
  .selects {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;

    .select {
      width: 100%;
      margin: 0 1rem 0.5rem 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }


  .box-row {
    display: flex;
    width: 100%;
    height: calc(38px + 2rem);
    margin: 0 auto;
    background-color: #eee;
    border-radius: 5px;
    color: #000;
    padding: 1rem;

    .select {
      display: flex;
      flex: 0 1 100%;
    }

    @media (min-width: 900px) {
      max-width: 900px;
      border-radius: 0.5rem;
    }
    @media (min-width: 1200px) {
      max-width: 1200px;
      border-radius: 0.5rem;
    }
  }


  .formula {
    display: block;
    width: 100%;
    height: 38px;
    color: #000;
    background-color: #f3f5f7;
    border: 1px solid #ccc;
    border-radius: 5px;

    input {
      display: inline-block;
      width: 100%;
      padding: 0.5rem;
      border: 0 !important;
      border-radius: 5px;
      resize: none;
    }

    @media (min-width: 750px) {
      width: calc(100% - 390px);
      flex: 1 0 calc(100% - 390px);
    }
  }

  .produced {
    position: relative;
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    @media (min-width: 750px) {
     flex-direction: row; 
      min-height: 100px;
      flex-wrap: wrap;
      padding: 0;
    }
    

    &:empty {
      display: none;
    }

    .item {
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 100px;
      height: auto;
      aspect-ratio: 1;
      margin: 0 0 1rem 0;

      @media (min-width: 750px) {
        width: auto;
        height: auto;
        max-height: 175px;
        margin: 0 1rem 1rem 0;
      }

      img {
        display: block;
        flex: 0 1 100%;
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .more {
    display: block;
    margin: 0 0 0.5rem;
    width: 100%;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }
}

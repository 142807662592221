.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: white;

  h1 {
    font-family: var(--font-fam-deco);
    font-size: 7vh;
    font-weight: 400;
  }

  verse {
    display: block;
    font-size: 3vh;
    font-family: var(--font-fam-deco);
    // font-style: italic;
    font-weight: 400;
  }

  &:hover {
    background-color: #e8c450;
    background-image: none;
  }
}
.page {
  min-height: 100vh;
  width: 100%;
  background-color: #000;
  overflow: hidden;
  animation: appear 0.5s ease-in-out forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.main {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0 1rem 3rem;
  color: #ccc;
  a {
    color: #fff;
  }
}

.mtl-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 36px;
  max-height: 36px;
  line-height: 36px;
  font-family: var(--font-fam-semi);
  font-size: 0.875rem;
  border: 0;
  background-color: #ffd700;
  border-radius: 5px;
  font-weight: 400;
  color: #000;
  margin: 0 0 0 1rem;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: deepskyblue;
    color: #fff;
    cursor: pointer;
  }

  &.s {
    padding: 6px 12px;
    width: 70px;
    height: auto;
    font-size: 0.75rem;
    margin-bottom: 4px;
  }

  &.secondary {
    background-color: deepskyblue;
    color: white;

    &:hover {
      color: #fff;
      background-color: #ffd700;
    }
  }
}

.box-col {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #eee;
  border-radius: 5px;
  color: #000;
  margin-bottom: 1rem;
  padding: 1rem;

  &:first-of-type {
    padding: 0;
  }

  @media (min-width: 900px) {
    max-width: 900px;
    border-radius: 0.5rem;
  }
}

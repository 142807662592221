.full-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.Producer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;

  h1 {
    color: #fff;

    h3 {
      font-size: 1rem;
      font-weight: 400;
      color: #999;
    }
  }

  .producer-nav {
    display: flex;
    width: 100%;
    max-width: 750px;
    color: #999;
    border-bottom: 1px solid #666;
    justify-content: center;
    margin: 0 auto 2rem;

    .nav-item {
      display: inline-flex;
      justify-content: center;
      width: 200px;
      font-size: 1rem;
      text-transform: uppercase;
      font-family: var(--font-fam-semi);
      padding: 0.5rem 1rem;
      cursor: pointer;

      &.active {
        border-bottom: 3px solid #fff;
        cursor: default;
        color: #fff;
      }
    }
  }

  .mode {
    width: 100%;
    height: auto;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .boxed {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: 750px) {
      flex-wrap: nowrap;
      margin-top: 1rem;
    }
  }

  .formula {
    display: block;
    width: 100%;
    flex: 1 0 100%;
    min-height: 38px;
    color: #000;
    background-color: #f3f5f7;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 0 0 0.5rem 0;

    textarea {
      display: inline-block;
      width: 100%;
      padding: 0.5rem;
      border: 0;
      border-radius: 5px;
      resize: none;
    }

    @media (min-width: 750px) {
      width: calc(100% - 390px);
      flex: 1 0 calc(100% - 390px);
      margin: 0 1rem 0 0;
    }
  }

  .produced {
    position: relative;
    padding: 1rem 1rem 0 1rem;
    background-color: #f3f5f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;

    @media (min-width: 750px) {
     flex-direction: row; 
      height: 290px;
    }
    

    &:empty {
      display: none;
    }

    .item {
      position: relative;
      display: inline-block;
      width: 100%;
      height: auto;
      aspect-ratio: 1;
      margin: 0 0 1rem 0;

      @media (min-width: 750px) {
        width: auto;
        height: auto;
        max-height: 256px;
        margin: 0 1rem 1rem 0;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }

  .alert {
    display: flex;
    width: 100%;
    justify-content: space-between;
    min-height: 3rem;
    padding: 1rem;
    background-color: #f3f5f7;
    border: 1px solid #333;

    .alert-text {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #333;
      margin: 0 1rem 0 0;
    }
  }

  .composite-prompt {
    flex: 0 1 100%;
    width: 100%;
    min-height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    background-color: #f3f5f7;
    border: 1px solid #333;
    border-radius: 5px;
    margin: 0 0 0.5rem 0;
    padding: 0 .5rem;

    @media (min-width: 750px) {
      flex: 0 1 calc(100% - 380px);
      width: calc(100% - 380px);
      margin: 0 1rem 0 0;
      line-height: 38px;

    }

    .full-width {
      width: 100%;
    }

    &--scene,
    &--character {
      width: 50%;
      display: flex;

      span {
        display: block;
        width: 100%;
      }
    }

    &--scene {
      border-left: 1px solid #333;
      padding-left: 1rem;
    }

  }

  .templates {
    color: #fff;
    margin: 1rem auto;

    @media (min-width: 750px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
      width: 100%;
      max-width: 1200px;
    }


    .scenes,
    .characters {
      display: block;
      margin: 1rem 0;
    }

    .item {
      font-size: 0.875rem;
      display: inline-flex;
      // border: 1px solid #fff;
      background-color: #f3f5f7;
      color: #000;
      padding: 0.25rem;
      margin: 0 0.25rem 0.25rem;

      &:hover {
        cursor: pointer;
        background-color: #fff;
      }
    }
  }

  .examples {
    color: #fff;

  }
}

.dz {
  position: relative;
  display: block;
  width: 100%;
  min-height: 100vw;
  margin: 2rem auto;

  @media (min-width: 750px) {
    width: 512px;
  }
  

  img {
    position: absolute;
    display: block;
    width: 100%;
    max-width: 512px;
    aspect-ratio: 1/1;
    z-index: 30;
  }

  .upload-space {
    aspect-ratio: 1;    
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    transform: translate(-50%, -50%);
    border: 1px dashed #fff;
    border-radius: 50%;
    background-color: #ffffff72;
    z-index: 40;
    color: #fff;
    text-align: center;
    cursor: pointer;

    .wrapper {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;
    }

  }

  button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #fff;
    border-radius: 50%;
    background-color: #ffffff72;
    z-index: 50;

    &:hover {
      cursor: pointer;
    }
  }

  .artboard {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vw;
    background-color: rgba(255, 255, 255, 0.25);
    z-index: 20;

    @media (min-width: 750px) {

      height: 512px;
    }
    

    img {
      display: block;
      position: relative;
      width: 100%;
      aspect-ratio: 1/1;
      z-index: 30;

      @media (min-width: 750px) {
        max-width: 512px;
      }
      
    }
  }

}


.row,
.boxed {

  .hints {
    width: 100%;
    padding: 0.5rem;
    height: 38px;
    background-color: #000;
    color: #ccc;
    border: 1px dotted #fff;
    text-align: center;
  }
}


.banners {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
  }

  .banner {
    width: 100%;
    padding: 1rem;
    color: #fff;

    @media (min-width: 640px) {
      width: calc(50% - 1rem);
    }

    @media (min-width: 900px) {
      width: calc(25% - 1rem);
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      border-radius: 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  
}
.toolbar--wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: black;
  border-bottom: 1px solid #444;
  margin: 0 0 1rem;
  z-index: 80;
}

.toolbar {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  height: 120px;

  @media (min-width: 900px) {
    max-width: 900px;
    padding: 1rem;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
    padding: 1rem;
  }


  .edge-left {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 0 0 120px;
    width: fit-content;
    height: 100%;
    margin-right: 1rem;

    a {
      display: inline-flex;
      align-items: center;
      color: white;
    }

    .logo-img {
      display: inline-flex;
      margin: -2px .5rem 0 0;
      width: 80px;
      height: auto;
      outline: 0;
    }

    .logo-text {
      display: none;

      @media (min-width: 420px) {
        display: inline-flex;
        font-family: var(--font-fam-deco);
        font-size: 1.25rem;
        height: 30px;
        font-weight: 400;
        color: white;
      }
    }

    a {
      display: inline-flex;
      color: white;

      &:hover {
        color: aqua;
      }
    }

  }

  .edge-right {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 220px;
    width: 220px;
    padding-right: 1rem;


    .btn-icon {
      font-size: 48px;
    }
  }

  .center {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 100%;
  }

  .menu {
    position: fixed;
    left: 0;
    top: 71px;
    width: 100vw;
    height: calc(100vh - 71px);
    background-color: black;

    &.transparent {
      left: 50%;
      background-color: transparent;
    }
  }

  .graphic-menu {
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: 900px) {
      flex-direction: row;
    }

    &__group {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      z-index: 20;
      background-color: black;

      &:hover {
        cursor: pointer;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 1rem;
      color: white;
      font-weight: 400;
    }


    .palette__image {
      position: relative;
      display: inline-flex;
      width: 120px;
      height: 120px;
      flex: 1 0 120px;
      background-color: black;
      margin: 5px;
      z-index: 20;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:hover {
        cursor: pointer;
        border: 2px solid aqua;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 21;

      }

    }
  }

}

.Guide {

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 1rem;
  margin: 0 auto;

  .box-row {
    background-color: #eee;
  }

  .containter {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    color: #fff;

    @media (min-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    img {
      width: 60%;
      height: auto;
      object-fit: cover;
      border-radius: 50%;
      border: 5px solid deepskyblue;

      @media (min-width: 900px) {
        border-width: 10px;
      }

    }
  }

  h1 {
    font-family: var(--font-fam-deco);
    font-size: 1.5rem;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
    margin: 0 0 .75rem 0;
    text-align: center;


    @media (min-width: 900px) {
      font-size: 2.5rem;
      // letter-spacing: 50px;
    }
  }

  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 1.5rem 0;

    @media (min-width: 900px) {
      font-size: 1.25rem;
      margin: 0 0 2rem 0;
    }
  }

  h3 {
    color: #000;
  }


  .col-20,
  .col-50,
  .col-60,
  .col-100 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #777;
    width: 100%;

    @media (min-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    img {
      width: 80%;
      height: auto;
      object-fit: cover;
      border-radius: 50%;
      border: 5px solid deepskyblue;

      @media (min-width: 900px) {
        border-width: 10px;
      }

    }
  }

  .col-100 {
    padding: 1rem;
    color: #777;

    img {
      width: 50%;
    }
  }

  .col-20 {

    width: 100%;
    flex: 1 1 100%;

    @media (min-width: 900px) {
      flex: 1 0 40%;
      max-width: 20%;
    }
  }

  .col-50 {
    flex: 1 1 100%;
    max-width: 50%;

    @media (min-width: 900px) {
      flex: 1 0 40%;
      max-width: 40%;
    }

  }

  .box {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: #eee;
    border-radius: 5px;
    color: #000;
    margin-bottom: 1rem;
    padding: 1rem;

    &:first-of-type {
      padding: 0;
    }

    @media (min-width: 900px) {
      max-width: 900px;
      border-radius: 0.5rem;
    }
  }

  .opponents {
    display: flex;
    justify-content: space-between;

    h2 {
      color: black;
      margin: 0 0 0.5rem 0;
    }
  }

  .topic {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 1rem 0 1rem;
  }

  .chat {
    align-items: space-between;
    justify-content: baseline;

    &:empty {
      display: none;
    }

    .chat__item {
      display: flex;
      position: relative;
      margin-right: 1rem;
      flex: 1 1 100%;
      align-items: flex-start;

      &.dialogue {
        justify-content: space-between;
      }

      p {
        width: 100%;
      }
    }
  }

  .talkbox {
    // margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: .5rem 1.5rem 0 1rem;
    background-color: #fff;
    border-radius: 5px;
    color: #000;
    max-height: min-content;

    @media (min-width: 900px) {
      min-width: 860px;
    }

    .talkbox__input {
      position: relative;
      top: -6px;
      border: 0;
    }

  }

  .meta {
    display: flex;
    width: 80px;
    padding-bottom: 4px;
  }

}

img.logo {
  display: block;
  width: 100%;
  max-width: 180px;
  padding: 2rem 0;
}

.avatar {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid deepskyblue;
  overflow: hidden;
  flex: 0 0 50px;
  top: 6px;
  margin: 0 1rem 1rem 0;

  img {
    width: 100%;
    height: auto;
  }
}

.Login {
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 0;

  p {
    margin: 0;
    padding: 1rem;
    color: #000;
  }

  .enter-form {
    display: flex;
    justify-content: space-between;
    padding: 0 1rem 1rem 1rem;

    .mtl-input {
      display: block;
      padding: 0.5rem;
      margin-right: 0.5rem;
      border: 0;
      border-radius: 5px;
      width: calc((100% - 105px - 2rem) / 2);
      flex: 0 0 calc((100% - 105px - 2rem) / 2);
    }
  }
}